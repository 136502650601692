<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="32"
    height="27"
    viewBox="0 0 32 27"
  >
    <defs>
      <path id="vcifa" d="M1353.98 652.61h-11.48v-4.86h11.48zm.7-5.57h-12.89v6.25h12.9z" />
      <path
        id="vcifb"
        d="M1337.18 642.71h21.96v19.55a3.68 3.68 0 0 1-3.68 3.68h-19.75a3.57 3.57 0 0 0 1.43-2.86zm-3.57 23.22a2.86 2.86 0 0 1-2.86-2.86v-15.03h5.71v15.03a2.86 2.86 0 0 1-2.89 2.86zm2.85-18.6H1330v15.74a3.57 3.57 0 0 0 3.57 3.57h21.9a4.4 4.4 0 0 0 4.39-4.39V642h-23.4z"
      />
      <path id="vcifc" d="M1341.79 657.57v-.71h12.89v.71z" />
      <path id="vcifd" d="M1341.79 661.86v-.72h12.89v.72z" />
    </defs>
    <g>
      <g transform="translate(-1329 -641)">
        <g>
          <use fill="#363636" xlink:href="#vcifa" />
          <use
            fill="#fff"
            fill-opacity="0"
            stroke="#363636"
            stroke-miterlimit="50"
            stroke-width=".8"
            xlink:href="#vcifa"
          />
        </g>
        <g>
          <use fill="#363636" xlink:href="#vcifb" />
          <use
            fill="#fff"
            fill-opacity="0"
            stroke="#363636"
            stroke-miterlimit="50"
            stroke-width=".8"
            xlink:href="#vcifb"
          />
        </g>
        <g>
          <use fill="#363636" xlink:href="#vcifc" />
          <use
            fill="#fff"
            fill-opacity="0"
            stroke="#363636"
            stroke-miterlimit="50"
            stroke-width=".8"
            xlink:href="#vcifc"
          />
        </g>
        <g>
          <use fill="#363636" xlink:href="#vcifd" />
          <use
            fill="#fff"
            fill-opacity="0"
            stroke="#363636"
            stroke-miterlimit="50"
            stroke-width=".8"
            xlink:href="#vcifd"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheNewspaperIcon',
};
</script>
